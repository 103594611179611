<template>
  <div
    class="home"
  >
    <b-tabs
      v-model="tabIndex"
      card
    >
      <b-tab
        :title="$t('PersonnelPlanning')"
        active
        @click="personnelPlanningTab"
      >
        <template #title>
          <!-- @click="personnelPlanningTab" -->
          <strong
            style="color: #0071AE"
          >{{ $t("PersonnelPlanning") }}</strong>
        </template>
        <!-- <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
          {{ $t("PersonnelPlanning") }}
        </p> -->
        <section
          style="display: flex; flex-direction: column"
        >
          <div style="display: flex; justify-content: space-between;align-items: center;">
            <div style="display: flex; width: 100%;">
              <button
                v-b-modal.add-personnel-to-planning
                class="button"
                style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 20px; width:200px"
                @click="fetchStores()"
              >
                <b-icon-plus />
                {{ $t('Employee') }}
              </button>
              <!-- <button
                v-b-modal.exchange-planning
                class="button"
                style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 20px; width:220px"
                @click="fetchStores()"
              >
                <b-icon-plus />
                {{ $t('EmployeeExchange') }}
              </button> -->

              <b-form-group
                id="input-group-2"
                :label="$t('Manager')"
                label-for="input-2"
              >
                <b-form-select
                  v-model="manager"
                  required
                  style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                  :options="selectManager"
                  :clearable="false"
                  label="userFullName"
                  :reduce="(e) => e.userId"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
              <div style="display: flex; flex-direction: row; align-items: end; padding-left: 20px; gap: 15px;">
                <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
                  <p style="margin-bottom: 0px; font-weight: bold;">
                    {{ $t('Internal') }}
                  </p>
                  <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(143, 188, 143); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
                </div>
                <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
                  <p style="margin-bottom: 0px; font-weight: bold;">
                    {{ $t('External') }}
                  </p>
                  <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(255, 215, 0); color: black; text-align: left; flex: 1; margin-right: 5px;padding: 7px;" />
                </div>
                <div
                  style="display: flex;align-items:end"
                >
                  <div style="display:flex; flex-direction: column;">
                    <div style="display: flex; height: 30px;margin-left: 10px;">
                      <div style="background-color: #d82521; width: 25px; height: 25px; margin-right: 10px;" />
                      <p>
                        {{ $t('lessStaff') }}
                      </p>
                    </div>
                    <div style="display: flex;height: 30px; margin-left: 10px;">
                      <div style="background-color: #347403; width: 25px; height: 25px; margin-right: 10px;" />
                      {{ $t('equalStaff') }}
                    </div>

                  </div>
                  <div style="display: flex;height: 30px; margin-left: 10px;">
                    <div style="background-color:#ff9b00; width: 25px; height: 25px; margin-right: 10px;" />
                    {{ $t('moreStaff') }}
                  </div>
                </div>
              </div>

            </div>
            <button
              v-b-modal.exchange-planning
              class="button"
              style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 10px; min-width:230px !important;"
            >
              <div style="display:flex;align-items: center;gap:5px;">
                <img
                  style="width: 20px;"
                  src="../assets/switch1.png"
                >
                {{ $t('EmployeeExchange') }}
              </div>
            </button>
            <b style="display: flex; align-items: center; margin-top: 15px; margin-right: 10px; font-size: 20px;width:9%">{{ $t(dateToString) }}</b>
            <b-form-group
              id="input-group-9"
              :label="$t('Date')"
              label-for="input-9"
            >
              <div
                class="year"
                style="margin-right: 10px;"
              >
                <button
                  @click="previousDay"
                >
                  <b-icon-arrow-left />
                </button>
                <date-picker
                  v-model="time1"
                  value-type="format"
                  class="form-control"
                  format="DD-MM-YYYY"
                  type="date"
                  placeholder="Select Date"
                  style="width:193px; border: none;"
                  :lang="lang"
                  :clearable="false"
                />
                <button
                  style="margin-left: 5px"
                  @click="nextDay"
                >
                  <b-icon-arrow-right />
                </button>
                <!-- </div> -->
              </div></b-form-group>
          </div>
          <div style="display: flex; flex-direction: row;">
            <div
              style="overflow-x: auto; width: 100%;"
            >
              <table class="personnelTable">
                <thead>
                  <tr>
                    <th
                      v-for="header in getEmployeePlanning"
                      :key="header.storeGLN"
                      v-b-tooltip.hover.top="$t('NoPlannedEmp') + header.numberOfPlannedEmployees"
                      class="table-header"
                      href="#"
                      tabindex="0"
                      style="border-right: 20px solid rgba(0, 0, 0, 0.05); border-left: 20px solid rgba(0, 0, 0, 0.05); border-top: 15px solid rgba(0, 0, 0, 0.05); margin-top: 10px;"
                      @click="storeID(header.storeId)"
                    >
                      <p :style="header.plannedEmployees.length == header.numberOfPlannedEmployees? 'color: white;background-color: rgb(52, 116, 3); padding: 6px; border-radius: 5px;margin: 0;': header.plannedEmployees.length > header.numberOfPlannedEmployees ? 'color:white; background-color: rgb(255, 155, 0); padding: 6px; border-radius: 5px;margin: 0;':'color:white; background-color: rgb(216, 37, 33); padding: 6px; border-radius: 5px;margin: 0;'">


                        {{ header.storeName }} - {{ header.numberOfPlannedEmployees }}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="index in rowCount"
                    :key="index"
                  >
                    <td
                      v-for="store in getEmployeePlanning"
                      :key="store.storeGLN"
                      style="width: 12.5%; color: black; font-size: 14px; font-weight: 300; border-right: 20px solid rgba(0, 0, 0, 0.05);  border-left: 20px solid rgba(0, 0, 0, 0.05);  padding-bottom: 15px;"
                    >
                      <div v-if="store.plannedEmployees && store.plannedEmployees.length >= index">
                        <div>
                          <div
                            class="header"
                            style="padding: 10px;text-align: left; border-radius: 5px 5px 5px 5px; margin-top: 5px; "
                            :style="store.plannedEmployees[index - 1].employeeType == 'Internal' ? 'background-color: rgb(143, 188, 143);' : 'background-color: rgb(255, 215, 0);'"
                            @click="fetchFreeUsers(store.plannedEmployees[index - 1].employeePlanningId)"
                          >
                            <div
                              style="display: flex;
                          justify-content: space-between;"
                            >
                              <b>{{ store.plannedEmployees[index - 1].employeeName }} </b>
                              <div style="padding-left:15px; display: flex; gap: 10px; align-items: center;">
                                <span
                                  v-b-modal.edit-personnel-planning

                                  @click="deleteEmployeer(store.plannedEmployees[index - 1].employeePlanningId,store.plannedEmployees[index - 1].employeeUserId ); checkEmployee(store.plannedEmployees[index - 1].employeePlanningId); getStore(store.storeId)"
                                > <b-icon-pencil /> </span>
                                <span
                                  v-b-modal.delete-modal-request
                                  @click="deleteEmployeer(store.plannedEmployees[index - 1].employeePlanningId,store.plannedEmployees[index - 1].employeeUserId ); checkEmployee(store.plannedEmployees[index - 1].employeePlanningId); getStore(store.storeId)"
                                ><b-icon-trash /></span>
                              </div>
                            </div>
                            <hr style="margin: 0px; border: 1px solid rgb(86, 86, 86);">
                          </div>
                        </div>
                      </div>
                      <!-- <hr v-if="store.plannedEmployees && store.plannedEmployees.length >= index"> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table
              class="ext-emp"
            >
              <div style="display: flex;justify-content: space-between; text-align: left; margin-top: 3px; height: 40px; padding-left: 5px;">
                <b
                  style="color: #0071AE"
                  class="extEmployee"
                > {{ $t('External') }} {{ $t('Employees') }} </b> <b
                  v-if="getVacationOverview"
                  class="extNumber"
                  style="color: #0071AE"
                >{{ countExternalEmployeesPersonnelPlanning }}</b>
              </div>
              <hr style="margin: 0px; border: 1px solid rgb(234, 233, 233); width: 90%; opacity: 1.25; align-self: center;">
              <div style="width: 100%; padding: 0px 10px 0px 10px;">
                <thead style="height: 44px !important">
                  <th
                    class="empName"
                    style="color: #0071AE"
                  >
                    {{ $t('EmployeeName') }}
                  </th>
                  <th
                    class="vacLeave"
                    style="color: #0071AE"
                  >
                    {{ $t('VacationLeaveReq') }}
                  </th>
                </thead>
                <hr style="margin: 0px; border: 1px solid rgb(234, 233, 233); width: 95%; opacity: 1.25; align-self: center; margin-left: 6px;">
              </div>
              <div
                v-if="getVacationOverview"
                style="width: 100%;"
              >
                <tr
                  v-for="name in getVacationOverview.storeDailyVacation"
                  :key="name.id"
                  style=" width: 100%; display: flex; justify-content: space-between; align-items: center; margin: 8px 0px 8px;"
                >
                  <!-- style="width: 98%; display: flex; height: 50px; align-items: center; gap: 50px; margin-top: 10px; text-align: left;" -->
                  <td
                    style="color: #141414;"
                    :style="name.vacationType == 'Vacation' ?
                      'background-color: red; color: white' : name.vacationType == 'SickLeave' ?
                        'background-color: lightskyblue ;color: black' : name.vacationType == 'AccidentLeave' ?
                          'background-color: rgb(63, 63, 169); color: white' : name.vacationType == 'Education' ?
                            'background-color: rgb(29, 236, 251); color: black' : name.vacationType == 'SpecialLeave' ?
                              'background-color: yellow; color: black' : name.vacationType == 'Maternity' ?
                                'background-color: rgb(226, 147, 193); color: white' : name.vacationType == 'Appointment' ?
                                  'background-color: green; color: white' : name.vacationType == 'DeathCase' ?
                                    'background-color: beige; color: white' : ''"
                  >
                    {{ name.fullName }}
                  </td>
                  <td
                    v-if="name.vacationType == 'Vacation'"
                    style="background-color: red; color: white"
                  >{{ $t('Vacation') }}</td>
                  <td
                    v-if="name.vacationType == 'SickLeave'"
                    style="background-color: lightskyblue; color: black"
                  >{{ $t('AddNew19') }}</td>
                  <td
                    v-if="name.vacationType == 'AccidentLeave'"
                    style="background-color: rgb(63, 63, 169); color: white"
                  >{{ $t('AddNew20') }}</td>
                  <td
                    v-if="name.vacationType == 'Education'"
                    style="background-color: rgb(29, 236, 251); color: black"
                  >{{ $t('AddNew21') }}</td>
                  <td
                    v-if="name.vacationType == 'SpecialLeave'"
                    style="background-color: yellow; color: black"
                  >{{ $t('AddNew22') }}</td>
                  <td
                    v-if="name.vacationType == 'Maternity'"
                    style="background-color: rgb(226, 147, 193); color: white"
                  >{{ $t('AddNew23') }}</td>
                  <td
                    v-if="name.vacationType == 'Appointment'"
                    style="background-color: green; color: white"
                  >{{ $t('AddNew24') }}</td>
                  <td
                    v-if="name.vacationType == 'DeathCase'"
                    style="background-color: beige; color: white"
                  >{{ $t('AddNew46') }}</td>
                </tr>
              </div>
            </table>
          </div>
        </section>
      </b-tab>
      <b-tab
        :title="$t('Joiner')"
        @click="joinerTab()"
      >
        <template #title>
          <!-- @click="joinerTab()" -->
          <strong
            style="color: #0071AE"
          >{{ $t("Joiner") }}</strong>
        </template>
        <section
          style="display: flex; flex-direction: column"
        >
          <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; width: 100%;">
              <button
                v-b-modal.add-personnel-to-planning
                class="button"
                style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 20px; width:220px"
                @click="fetchStores()"
              >
                <b-icon-plus />
                {{ $t('Employee') }}
              </button>
              <b-form-group
                id="input-group-2"
                :label="$t('Manager')"
                label-for="input-2"
              >
                <b-form-select
                  v-model="manager"
                  required
                  style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                  :options="selectManager"
                  :clearable="false"
                  label="userFullName"
                  :reduce="(e) => e.userId"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
              <div style="display: flex; flex-direction: row; align-items: center; padding-left: 20px; gap: 15px;">
                <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
                  <p style="margin-bottom: 0px; font-weight: bold;">
                    {{ $t('Internal') }}
                  </p>
                  <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(143, 188, 143); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
                </div>
                <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
                  <p style="margin-bottom: 0px; font-weight: bold;">
                    {{ $t('External') }}
                  </p>
                  <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(255, 215, 0); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
                </div>
                <!-- <div
                  style="display: flex;"
                >
                  <div style="display: flex; height: 30px;margin-left: 10px;">
                    <div style="background-color: #d82521; width: 25px; height: 25px; margin-right: 10px;" />
                    <p>
                      {{ $t('lessStaff') }}
                    </p>
                  </div>
                  <div style="display: flex;height: 30px; margin-left: 10px;">
                    <div style="background-color: #347403; width: 25px; height: 25px; margin-right: 10px;" />
                    {{ $t('equalStaff') }}
                  </div>
                  <div style="display: flex;height: 30px; margin-left: 10px;">
                    <div style="background-color:#ff9b00; width: 25px; height: 25px; margin-right: 10px;" />
                    {{ $t('moreStaff') }}
                  </div>
                </div> -->
              </div>
            </div>
            <b style="display: flex; align-items: center; margin-top: 15px; margin-right: 10px; font-size: 20px;">{{ $t(dateToString) }}</b>
            <b-form-group
              id="input-group-9"
              :label="$t('Date')"
              label-for="input-9"
            >
              <div
                class="year"
                style="margin-right: 10px;"
              >
                <button
                  @click="previousDay"
                >
                  <b-icon-arrow-left />
                </button>
                <date-picker
                  v-model="time1"
                  value-type="format"
                  class="form-control"
                  format="DD-MM-YYYY"
                  type="date"
                  placeholder="Select Date"
                  style="width:193px; border: none;"
                  :lang="lang"
                  :clearable="false"
                />
                <button
                  style="margin-left: 5px"
                  @click="nextDay"
                >
                  <b-icon-arrow-right />
                </button>
                <!-- </div> -->
              </div></b-form-group>
          </div>
          <div style="display: flex; flex-direction: row;">
            <div
              style="overflow-x: auto; width: 100%;"
            >
              <table class="personnelTable">
                <thead>
                  <tr>
                    <th
                      v-for="header in getEmployeePlanning"
                      :key="header.storeGLN"
                      v-b-tooltip.hover.top="$t('NoPlannedEmp') + header.numberOfPlannedEmployees"
                      class="table-header"
                      href="#"
                      tabindex="0"
                      style="border-right: 20px solid rgba(0, 0, 0, 0.05); border-left: 20px solid rgba(0, 0, 0, 0.05); border-top: 15px solid rgba(0, 0, 0, 0.05); margin-top: 10px; color:#0071AE"
                      @click="storeID(header.storeId)"
                    >
                      <!-- :style="header.plannedEmployees.length == header.numberOfPlannedEmployees? 'color: white;background-color: rgb(52, 116, 3); padding: 6px; border-radius: 5px;margin: 0;': header.plannedEmployees.length > header.numberOfPlannedEmployees ? 'color:white; background-color: rgb(255, 155, 0); padding: 6px; border-radius: 5px;margin: 0;':'color:white; background-color: rgb(216, 37, 33); padding: 6px; border-radius: 5px;margin: 0;'" -->
                      <p style="color:#0071AE">
                        {{ header.storeName }} - {{ header.plannedEmployees.length }}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="index in rowCount"
                    :key="index"
                  >
                    <td
                      v-for="store in getEmployeePlanning"
                      :key="store.storeGLN"
                      style="width: 12.5%; color: black; font-size: 14px; font-weight: 300; border-right: 20px solid rgba(0, 0, 0, 0.05);  border-left: 20px solid rgba(0, 0, 0, 0.05);  padding-bottom: 15px;"
                    >
                      <div v-if="store.plannedEmployees && store.plannedEmployees.length >= index">
                        <div>
                          <div
                            class="header"
                            style="padding: 10px;text-align: left; border-radius: 5px 5px 5px 5px; margin-top: 5px; "
                            :style="store.plannedEmployees[index - 1].employeeType == 'Internal' ? 'background-color: rgb(143, 188, 143);' : 'background-color: rgb(255, 215, 0);'"
                            @click="fetchFreeUsers(store.plannedEmployees[index - 1].employeePlanningId)"
                          >
                            <div
                              style="display: flex;
                          justify-content: space-between;"
                            >
                              <b>{{ store.plannedEmployees[index - 1].employeeName }} </b>
                              <div style="padding-left:15px; display: flex; gap: 10px; align-items: center;">
                                <!-- <span
                                  v-b-modal.edit-personnel-planning
                                  @click="deleteEmployeer(store.plannedEmployees[index - 1].employeePlanningId,store.plannedEmployees[index - 1].employeeUserId ); checkEmployee(store.plannedEmployees[index - 1].employeePlanningId); getStore(store.storeId)"
                                > <b-icon-pencil /> </span>-->
                                <span
                                  v-b-modal.delete-modal-request
                                  @click="deleteEmployeer(store.plannedEmployees[index - 1].employeePlanningId,store.plannedEmployees[index - 1].employeeUserId ); checkEmployee(store.plannedEmployees[index - 1].employeePlanningId); getStore(store.storeId)"
                                ><b-icon-trash /></span>
                              </div>
                            </div>
                            <hr style="margin: 0px; border: 1px solid rgb(86, 86, 86);">
                          </div>
                        </div>
                      </div>
                      <!-- <hr v-if="store.plannedEmployees && store.plannedEmployees.length >= index"> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table
              class="ext-emp"
            >
              <div style="display: flex;justify-content: space-between; text-align: left; margin-top: 3px; height: 40px; padding-left: 5px;">
                <b
                  style="color: #0071AE"
                  class="extEmployee"
                > {{ $t('External') }} {{ $t('Employees') }} </b> <b
                  v-if="getVacationOverview"
                  class="extNumber"
                  style="color: #0071AE"
                >{{ countExternalEmployeesPersonnelPlanning }}</b>
              </div>
              <hr style="margin: 0px; border: 1px solid rgb(234, 233, 233); width: 90%; opacity: 1.25; align-self: center;">
              <div style="width: 100%; padding: 0px 10px 0px 10px;">
                <thead style="height: 44px !important">
                  <th
                    class="empName"
                    style="color: #0071AE"
                  >
                    {{ $t('EmployeeName') }}
                  </th>
                  <th
                    class="vacLeave"
                    style="color: #0071AE"
                  >
                    {{ $t('VacationLeaveReq') }}
                  </th>
                </thead>
                <hr style="margin: 0px; border: 1px solid rgb(234, 233, 233); width: 95%; opacity: 1.25; align-self: center; margin-left: 6px;">
              </div>
              <div
                v-if="getVacationOverview"
                style="width: 100%;"
              >
                <tr
                  v-for="name in getVacationOverview.storeDailyVacation"
                  :key="name.id"
                  style=" width: 100%; display: flex; justify-content: space-between; align-items: center; margin: 8px 0px 8px;"
                >
                  <!-- style="width: 98%; display: flex; height: 50px; align-items: center; gap: 50px; margin-top: 10px; text-align: left;" -->
                  <td
                    style="color: #141414;"
                    :style="name.vacationType == 'Vacation' ?
                      'background-color: red; color: white' : name.vacationType == 'SickLeave' ?
                        'background-color: lightskyblue ;color: black' : name.vacationType == 'AccidentLeave' ?
                          'background-color: rgb(63, 63, 169); color: white' : name.vacationType == 'Education' ?
                            'background-color: rgb(29, 236, 251); color: black' : name.vacationType == 'SpecialLeave' ?
                              'background-color: yellow; color: black' : name.vacationType == 'Maternity' ?
                                'background-color: rgb(226, 147, 193); color: white' : name.vacationType == 'Appointment' ?
                                  'background-color: green; color: white' : name.vacationType == 'DeathCase' ?
                                    'background-color: beige; color: white' : ''"
                  >
                    {{ name.fullName }}
                  </td>
                  <td
                    v-if="name.vacationType == 'Vacation'"
                    style="background-color: red; color: white"
                  >{{ $t('Vacation') }}</td>
                  <td
                    v-if="name.vacationType == 'SickLeave'"
                    style="background-color: lightskyblue; color: black"
                  >{{ $t('AddNew19') }}</td>
                  <td
                    v-if="name.vacationType == 'AccidentLeave'"
                    style="background-color: rgb(63, 63, 169); color: white"
                  >{{ $t('AddNew20') }}</td>
                  <td
                    v-if="name.vacationType == 'Education'"
                    style="background-color: rgb(29, 236, 251); color: black"
                  >{{ $t('AddNew21') }}</td>
                  <td
                    v-if="name.vacationType == 'SpecialLeave'"
                    style="background-color: yellow; color: black"
                  >{{ $t('AddNew22') }}</td>
                  <td
                    v-if="name.vacationType == 'Maternity'"
                    style="background-color: rgb(226, 147, 193); color: white"
                  >{{ $t('AddNew23') }}</td>
                  <td
                    v-if="name.vacationType == 'Appointment'"
                    style="background-color: green; color: white"
                  >{{ $t('AddNew24') }}</td>
                  <td
                    v-if="name.vacationType == 'DeathCase'"
                    style="background-color: beige; color: white"
                  >{{ $t('AddNew46') }}</td>
                </tr>
              </div>
            </table>
          </div>
        </section>
      </b-tab>
      <b-tab
        :title="$t('Unassigned')"
        @click="inNoRoute()"
      >
        <template #title>
          <!-- @click="joinerTab()" -->
          <strong
            style="color: #0071AE"
          >{{ $t("Unassigned") }}</strong>
        </template>
        <section
          style="display: flex; flex-direction: column"
        >
          <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; width: 100%;">
              <!-- <button
                v-b-modal.add-personnel-to-planning
                class="button"
                style="border-radius: 6px; height: 36px; margin-top: 25px; margin-right: 20px; width:220px"
                @click="fetchStores()"
              >
                <b-icon-plus />
                {{ $t('Employee') }}
              </button> -->

              <b-form-group
                id="input-group-2"
                :label="$t('Manager')"
                label-for="input-2"
              >
                <b-form-select
                  v-model="manager"
                  required
                  style="width:100%;padding: 8.5px; color: #82868c; border-radius: 8px; border: none; box-shadow: 0px 0px 10px rgb(40 41 61 / 14%);"
                  :options="selectManager"
                  :clearable="false"
                  label="userFullName"
                  :reduce="(e) => e.userId"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
              <div style="display: flex; flex-direction: row; align-items: center; padding-left: 20px; gap: 15px;">
                <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
                  <p style="margin-bottom: 0px; font-weight: bold;">
                    {{ $t('Internal') }}
                  </p>
                  <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(143, 188, 143); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
                </div>
                <div style="display: flex; flex-direction: row-reverse; gap: 0px; align-items: center;">
                  <p style="margin-bottom: 0px; font-weight: bold;">
                    {{ $t('External') }}
                  </p>
                  <span style="width:30px;height:30px;border-radius: 50%;font-weight:normal;background-color: rgb(255, 215, 0); color: black; text-align: left; flex: 1; margin-right: 10px;padding: 7px;" />
                </div>
                <!-- <div
                  style="display: flex;"
                >
                  <div style="display: flex; height: 30px;margin-left: 10px;">
                    <div style="background-color: #d82521; width: 25px; height: 25px; margin-right: 10px;" />
                    <p>
                      {{ $t('lessStaff') }}
                    </p>
                  </div>
                  <div style="display: flex;height: 30px; margin-left: 10px;">
                    <div style="background-color: #347403; width: 25px; height: 25px; margin-right: 10px;" />
                    {{ $t('equalStaff') }}
                  </div>
                  <div style="display: flex;height: 30px; margin-left: 10px;">
                    <div style="background-color:#ff9b00; width: 25px; height: 25px; margin-right: 10px;" />
                    {{ $t('moreStaff') }}
                  </div>
                </div> -->
              </div>
            </div>
            <b style="display: flex; align-items: center; margin-top: 15px; margin-right: 10px; font-size: 20px;">{{ $t(dateToString) }}</b>
            <b-form-group
              id="input-group-9"
              :label="$t('Date')"
              label-for="input-9"
            >
              <div
                class="year"
                style="margin-right: 10px;"
              >
                <button
                  @click="previousDay"
                >
                  <b-icon-arrow-left />
                </button>
                <date-picker
                  v-model="time1"
                  value-type="format"
                  class="form-control"
                  format="DD-MM-YYYY"
                  type="date"
                  placeholder="Select Date"
                  style="width:193px; border: none;"
                  :lang="lang"
                  :clearable="false"
                />
                <button
                  style="margin-left: 5px"
                  @click="nextDay"
                >
                  <b-icon-arrow-right />
                </button>
                <!-- </div> -->
              </div></b-form-group>
          </div>
          <div style="display: flex; flex-direction: row;">
            <div
              style="overflow-x: auto; width: 100%; padding-bottom: 20px"
            >
              <table class="personnelTable">
                <thead>
                  <tr>
                    <th
                      v-for="header in getUnAssignedEmployeePlan"
                      :key="header.storeGLN"
                      class="table-header"
                      href="#"
                      tabindex="0"
                      style="border-right: 20px solid rgba(0, 0, 0, 0.05); border-left: 20px solid rgba(0, 0, 0, 0.05); border-top: 15px solid rgba(0, 0, 0, 0.05); margin-top: 10px; color: #0071AE"
                      @click="storeID(header.storeId)"
                    >
                      <!-- :style="header.plannedEmployees.length == header.numberOfPlannedEmployees? 'color: white;background-color: rgb(52, 116, 3); padding: 6px; border-radius: 5px;margin: 0;': header.plannedEmployees.length > header.numberOfPlannedEmployees ? 'color:white; background-color: rgb(255, 155, 0); padding: 6px; border-radius: 5px;margin: 0;':'color:white; background-color: rgb(216, 37, 33); padding: 6px; border-radius: 5px;margin: 0;'" -->
                      <p>

                        {{ header.storeName }} - {{ header.plannedEmployees.length }}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="index in rowCount"
                    :key="index"
                  >
                    <td
                      v-for="store in getUnAssignedEmployeePlan"
                      :key="store.storeGLN"
                      style="width: 12.5%; color: black; font-size: 14px; font-weight: 300; border-right: 20px solid rgba(0, 0, 0, 0.05);  border-left: 20px solid rgba(0, 0, 0, 0.05);  padding-bottom: 15px;"
                    >
                      <div v-if="store.plannedEmployees && store.plannedEmployees.length >= index">
                        <div>
                          <div
                            v-b-modal.leave-request-personnel-planning
                            class="header"
                            style="padding: 10px;text-align: left; border-radius: 5px 5px 5px 5px; margin-top: 5px; "
                            :style="store.plannedEmployees[index - 1].employeeType == 'Internal' ? 'background-color: rgb(143, 188, 143);' : 'background-color: rgb(255, 215, 0);'"
                            @click="vacationLeave(store.plannedEmployees[index - 1].employeeUserId, store.plannedEmployees[index - 1].employeePlanningId)"
                          >
                            <!-- @click="fetchFreeUsers(store.plannedEmployees[index - 1].employeePlanningId)" -->
                            <div
                              style="display: flex;
                          justify-content: space-between;"
                            >
                              <b>{{ store.plannedEmployees[index - 1].employeeName }} </b>
                              <div style="padding-left:15px; display: flex; gap: 10px; align-items: center;">
                                <!-- <span
                                  v-b-modal.edit-personnel-planning
                                  @click="deleteEmployeer(store.plannedEmployees[index - 1].employeePlanningId,store.plannedEmployees[index - 1].employeeUserId ); checkEmployee(store.plannedEmployees[index - 1].employeePlanningId); getStore(store.storeId)"
                                > <b-icon-pencil /> </span>
                                <span
                                  v-b-modal.delete-modal-request
                                  @click="deleteEmployeer(store.plannedEmployees[index - 1].employeePlanningId,store.plannedEmployees[index - 1].employeeUserId ); checkEmployee(store.plannedEmployees[index - 1].employeePlanningId); getStore(store.storeId)"
                                ><b-icon-trash /></span> -->
                              </div>
                            </div>
                            <hr style="margin: 0px; border: 1px solid rgb(86, 86, 86);">
                          </div>
                        </div>
                      </div>
                      <!-- <hr v-if="store.plannedEmployees && store.plannedEmployees.length >= index"> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table
              class="ext-emp"
              style="padding-bottom: 40px;"
            >
              <!-- <div style="display: flex;justify-content: space-between; text-align: left; margin-top: 3px; height: 40px; padding-left: 5px;">
                <b
                  style="color:#0071AE"
                  class="extEmployee"
                > {{ $t('External') }} {{ $t('Employees') }} </b> <b
                  v-if="getVacationOverview"
                  class="extNumber"
                  style="color:#0071AE"
                >{{ countExternalEmployeesUnAssigned }}</b>
              </div> -->
              <!-- <hr style="margin: 0px; border: 1px solid rgb(234, 233, 233); width: 90%; opacity: 1.25; align-self: center;"> -->
              <div style="width: 100%; padding: 0px 10px 0px 10px;">
                <thead style="height: 44px !important">
                  <th
                    class="empName"
                    style="color: #0071AE"
                  >
                    {{ $t('EmployeeName') }}
                  </th>
                  <th
                    class="vacLeave"
                    style="color: #0071AE"
                  >
                    {{ $t('VacationLeaveReq') }}
                  </th>
                </thead>
                <hr style="margin: 0px; border: 1px solid rgb(234, 233, 233); width: 95%; opacity: 1.25; align-self: center; margin-left: 6px;">
              </div>
              <div
                v-if="getVacationOverview"
                style="width: 100%;"
              >
                <tr
                  v-for="name in getVacationOverview.storeDailyVacation"
                  :key="name.id"
                  style=" width: 100%; display: flex; justify-content: space-between; align-items: center; margin: 8px 0px 8px;"
                >
                  <!-- style="width: 98%; display: flex; height: 50px; align-items: center; gap: 50px; margin-top: 10px; text-align: left;" -->
                  <td
                    style="color: #141414;"
                    :style="name.vacationType == 'Vacation' ?
                      'background-color: red; color: white' : name.vacationType == 'SickLeave' ?
                        'background-color: lightskyblue ;color: black' : name.vacationType == 'AccidentLeave' ?
                          'background-color: rgb(63, 63, 169); color: white' : name.vacationType == 'Education' ?
                            'background-color: rgb(29, 236, 251); color: black' : name.vacationType == 'SpecialLeave' ?
                              'background-color: yellow; color: black' : name.vacationType == 'Maternity' ?
                                'background-color: rgb(226, 147, 193); color: white' : name.vacationType == 'Appointment' ?
                                  'background-color: green; color: white' : name.vacationType == 'DeathCase' ?
                                    'background-color: beige; color: white' : ''"
                  >
                    {{ name.fullName }}
                  </td>
                  <td
                    v-if="name.vacationType == 'Vacation'"
                    style="background-color: red; color: white"
                  >{{ $t('Vacation') }}</td>
                  <td
                    v-if="name.vacationType == 'SickLeave'"
                    style="background-color: lightskyblue; color: black"
                  >{{ $t('AddNew19') }}</td>
                  <td
                    v-if="name.vacationType == 'AccidentLeave'"
                    style="background-color: rgb(63, 63, 169); color: white"
                  >{{ $t('AddNew20') }}</td>
                  <td
                    v-if="name.vacationType == 'Education'"
                    style="background-color: rgb(29, 236, 251); color: black"
                  >{{ $t('AddNew21') }}</td>
                  <td
                    v-if="name.vacationType == 'SpecialLeave'"
                    style="background-color: yellow; color: black"
                  >{{ $t('AddNew22') }}</td>
                  <td
                    v-if="name.vacationType == 'Maternity'"
                    style="background-color: rgb(226, 147, 193); color: white"
                  >{{ $t('AddNew23') }}</td>
                  <td
                    v-if="name.vacationType == 'Appointment'"
                    style="background-color: green; color: white"
                  >{{ $t('AddNew24') }}</td>
                  <td
                    v-if="name.vacationType == 'DeathCase'"
                    style="background-color: beige; color: white"
                  >{{ $t('AddNew46') }}</td>
                </tr>
              </div>
            </table>
          </div>
        </section>
      </b-tab>
    </b-tabs>
    <AddPersonnelPlanning
      :manager="manager"
      @AddPersonnel="AddPersonnelEmployees"
    />
    <ExchangeEmployeesPlanning
      :manager="manager"
      :time="time1"
      :store-names="storeNames"
      @exchange="ExchangeEmployee"
    />
    <EditPersonnelPlanning
      :id="userIdd"
      :user="employeeToBeReplaced"
      :employee="userId"
      :store-id="storeIdd"
      :date="selectedDate"
      :manageri="manager"
      @edit="editPersonnelPlanning"
    />
    <DeleteUserModal
      :id="userIdd"
      :employee="userId"
      :store-id="storeIdd"
      :date="selectedDate"
      :manageri="manager"
      :tab-index="tabIndex"
      :time1="time1"
      @cancel="deleteEmployee"
    />
    <LeaveRequestPersonnelPlanningModalVue @pushFormUserPersonnel="submitVacation" />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import AddPersonnelPlanning from './AddPersonnelPlanningModal.vue'
import EditPersonnelPlanning from './EditPersonnelPlanningModal.vue'
import DeleteUserModal from './DeleteUserModal.vue';
import LeaveRequestPersonnelPlanningModalVue from './LeaveRequestPersonnelPlanningModal.vue';
import ExchangeEmployeesPlanning from './ExchangeEmployeesPlanning.vue'
// import Spinner from './Spinner/Spinner.vue';


export default {
  name: 'PersonnelPlanningListing',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Sidebar,
    DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    Navbar,
    AddPersonnelPlanning,
    EditPersonnelPlanning,
    DeleteUserModal,
    LeaveRequestPersonnelPlanningModalVue,
    ExchangeEmployeesPlanning,
    // Spinner,
  },
  data() {
    return {
      filialeIdShow: '',
      routeIdShow: '',
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      tabIndex: 0,
      userId: null,
      userPlanId: null,
      truckShow: {},
      selectedDate: moment().format('YYYY-MM-DD'),
      time1: moment().format('DD-MM-YYYY'),
      time2: moment().format('DD-MM-YYYY'),
      startDate: moment().format('YYYY-MM-DDT08:00:00.SSSZ'),
      endDate: moment().format('YYYY-MM-DDT17:00:00.SSSZ'),
      manager: null,
      employeeToBeReplaced: null,
      storeIdd: null,
      isInitialRender: true,
      userIdd: null,
      dateToString: null,
    };
  },
  computed: {
    ...mapGetters(['getEmployeePlanning', 'getIsLoading', 'getFreeManagers', 'getStoreManagementOverview', 'getVacationOverview', 'getFreeRegularEmployee', 'getUnAssignedEmployeePlan']),
    selectManager() {
      return this.getFreeManagers.map((option) => {
        return {
          value: option.userId,
          text: option.userFullName,
        }
      })
    },
    storeNames() {
      return this.getEmployeePlanning.map((entry) => { return { storeId: entry.storeId, storeName: entry.storeName } });
    },
    countExternalEmployeesPersonnelPlanning() {
      let externalEmployeesCount = 0;
      this.getEmployeePlanning.forEach((store) => {
        store.plannedEmployees.forEach((employee) => {
          if (employee.employeeType === 'External') {
            externalEmployeesCount++;
          }
        });
      });
      return externalEmployeesCount;
    },
    totalTrucks() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.numberOfTrucks }, 0);
    },
    totalEmployees() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.numberOfEmployees }, 0);
    },
    totalEstimated() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.numberOfEmployees }, 0);
    },
    totalEstimatedEarnings() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.estimatedEarnings }, 0);
    },
    totalCurrentEarnings() {
      return this.getStoreManagementOverview.reduce((sum, store) => { return sum + store.storeDailyOverviewCalculations.currentEarnings }, 0);
    },
    rowCount() {
      let maxRowCount = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const store of this.getEmployeePlanning) {
        if (store.plannedEmployees && store.plannedEmployees.length > maxRowCount) {
          maxRowCount = store.plannedEmployees.length;
        }
      }
      return maxRowCount;
    },
  },
  watch: {
    time1(value) {
      // this.changeLoadingtoTrue(false);

      if (this.tabIndex == 0) {
        if (!this.isInitialRender) {
          this.startDate = moment(value, 'DD-MM-YYYY').hour(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          this.endDate = moment(value, 'DD-MM-YYYY').hour(23).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          this.dateToString = moment(this.startDate).format('dddd')
          this.employeePlanning({
            userId: this.manager,
            startDate: this.startDate,
            endDate: this.endDate,
            isCarpenter: false,

          }).then(() => {
            // this.changeLoadingtoTrue(true);
          });
          this.loadVacationOverview({
            managerId: this.manager,
            date: this.startDate,
          }).then(() => {
            // this.changeLoadingtoTrue(true);
          });
        } else {
          this.isInitialRender = false;
        }
      } else if (this.tabIndex == 1) {
        if (!this.isInitialRender) {
          this.startDate = moment(value, 'DD-MM-YYYY').hour(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          this.endDate = moment(value, 'DD-MM-YYYY').hour(23).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          this.dateToString = moment(this.startDate).format('dddd')
          this.employeePlanning({
            userId: this.manager,
            startDate: this.startDate,
            endDate: this.endDate,
            isCarpenter: true,

          }).then(() => {
            // this.changeLoadingtoTrue(true);
          });
          this.loadVacationOverview({
            managerId: this.manager,
            date: this.startDate,
          }).then(() => {
            // this.changeLoadingtoTrue(true);
          });
        } else {
          this.isInitialRender = false;
        }
      } else {
        this.startDate = moment(value, 'DD-MM-YYYY').hour(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        this.endDate = moment(value, 'DD-MM-YYYY').hour(23).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        this.unAssignedEmployeePlanning({
          userId: this.manager,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        this.loadVacationOverview({
          managerId: this.manager,
          date: this.startDate,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
        this.employeePlanning({
          userId: this.manager,
          startDate: this.startDate,
          endDate: this.endDate,
          isCarpenter: false,

        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      }
    },
    // time2(value) {
    //   this.endDate = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
    //   this.employeePlanning({
    //     userId: this.manager,
    //     startDate: this.startDate,
    //     endDate: this.endDate,
    //   })
    // },
    // selectedDate: {
    //   handler(value) {
    //           // this.changeLoadingtoTrue(false);


    //     this.loadStoreManagementOverview({
    //       userId: this.manager,
    //       startDate: value,
    //     }).then((res) => {
    //       console.log(res);
    //       // this.changeLoadingtoTrue(true);
    //     });
    //           // this.changeLoadingtoTrue(false);

    //     this.loadVacationOverview({
    //       managerId: this.manager,
    //       date: value,
    //     });
    //   },
    // },
    manager: {
      handler() {
        // this.changeLoadingtoTrue(false);

        if (this.tabIndex == 0) {
          if (!this.isInitialRender) {
          // console.log('date', value);
            this.employeePlanning({
              userId: this.manager,
              startDate: this.startDate,
              endDate: this.endDate,
              isCarpenter: false,

            }).then(() => {
              // this.changeLoadingtoTrue(true);
            });
            this.loadVacationOverview({
              managerId: this.manager,
              date: this.startDate,
            }).then(() => {
              // this.changeLoadingtoTrue(true);
            });
          } else {
            this.isInitialRender = false;
          }
        } else if (this.tabIndex == 1) {
          if (!this.isInitialRender) {
          // console.log('date', value);
            this.employeePlanning({
              userId: this.manager,
              startDate: this.startDate,
              endDate: this.endDate,
              isCarpenter: true,

            }).then(() => {
              // this.changeLoadingtoTrue(true);
            });
            this.loadVacationOverview({
              managerId: this.manager,
              date: this.startDate,
            }).then(() => {
              // this.changeLoadingtoTrue(true);
            });
          } else {
            this.isInitialRender = false;
          }
        } else {
          // console.log('date', value);
          this.unAssignedEmployeePlanning({
            userId: this.manager,
            startDate: this.startDate,
            endDate: this.endDate,
          })
        }
      },
    },
  },
  mounted() {
    // this.changeLoadingtoTrue(false);


    // this.employeePlanning({
    //   userId: '84190cf6-d2a8-46ec-d7b1-08db5b65fdb1',
    //   startDate: '2023-07-20',
    //   endDate: '2023-07-31',
    // })
    this.dateToString = moment(this.selectedDate).format('dddd')

    this.loadFreeManagers().then(() => {
      if (this.getFreeManagers.length > 0) {
        const firstManagerId = this.getFreeManagers[0].userId;
        this.manager = firstManagerId; // Set the v-model of vue-select to the first manager
        this.employeePlanning({
          userId: firstManagerId,
          startDate: this.startDate,
          endDate: this.endDate,
          isCarpenter: false,

        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
        // this.changeLoadingtoTrue(false);

        this.loadVacationOverview({
          managerId: firstManagerId,
          date: this.selectedDate,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
        // this.changeLoadingtoTrue(false);
      }
    });
  },
  methods: {
    ...mapActions(['move_planned_employee_to_another_store', 'leaveRequest', 'resetUnAssigned', 'unAssignedEmployeePlanning', 'resetEmployeePlanning', 'potential_tours_by_employeePlanId', 'getTourNameByEmployeeUserIdStartAndEndShift', 'editEmployeePlanning', 'freeEmployees_new', 'deleteEmployeePlanning', 'addEmployeePlanning', 'loadFilialePagination', 'employeePlanning', 'changeLoadingtoTrue', 'loadStoreManagementOverview', 'loadFreeManagers', 'loadVacationOverview', 'loadFreeRegularEmployee', 'changeLoadingtoTrue']),
    fetchStores() {
      this.loadFilialePagination({
        status: true,
        storeName: null,
        pageNumber: 1,
        pageSize: 50,
      })
    },
    vacationLeave(value, value2) {
      this.userId = value
      this.userPlanId = value2
    },
    submitVacation(value) {
      console.log(value)
      const res = moment.tz.guess();
      const temp1 = moment.tz(this.time1, 'DD-MM-yyyy', res).set({ hour: 0, minute: 0 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const temp2 = moment.tz(this.time1, 'DD-MM-yyyy', res).set({ hour: 23, minute: 59 }).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      const obj = {
        employeeUserId: this.userId,
        vacationType: value,
        startDate: temp1,
        endDate: temp2,
      }
      console.log(obj)
      this.leaveRequest({
        object: obj,
        successCallback: () => {
          this.loadVacationOverview({
            managerId: this.manager,
            date: this.selectedDate,
          })
          this.unAssignedEmployeePlanning({
            userId: this.manager,
            startDate: this.startDate,
            endDate: this.endDate,
          })
          this.deleteEmployeePlanning({
            employeePlanId: this.userPlanId,
          })
        },
      })
    },
    fetchFreeUsers(value) {
      this.employeeToBeReplaced = value
      this.freeEmployees_new({
        userId: this.manager,
        startShift: this.startDate,
        endShift: this.endDate,
      })
    },
    personnelPlanningTab() {
      this.resetEmployeePlanning()
      this.resetUnAssigned()
      this.employeePlanning({
        userId: this.manager,
        startDate: this.startDate,
        endDate: this.endDate,
        isCarpenter: false,

      })
      this.loadVacationOverview({
        managerId: this.manager,
        date: this.startDate,
      }).then(() => {
        // this.changeLoadingtoTrue(true);
      });
    },
    joinerTab() {
      this.resetEmployeePlanning()
      this.resetUnAssigned()
      this.employeePlanning({
        userId: this.manager,
        startDate: this.startDate,
        endDate: this.endDate,
        isCarpenter: true,

      })
      this.loadVacationOverview({
        managerId: this.manager,
        date: this.startDate,
      }).then(() => {
        // this.changeLoadingtoTrue(true);
      });
    },
    inNoRoute() {
      // this.resetEmployeePlanning()
      // this.resetUnAssigned()
      this.unAssignedEmployeePlanning({
        userId: this.manager,
        startDate: this.startDate,
        endDate: this.endDate,
      })
      this.loadVacationOverview({
        managerId: this.manager,
        date: this.startDate,
      }).then(() => {
        // this.changeLoadingtoTrue(true);
      });
      this.employeePlanning({
        userId: this.manager,
        startDate: this.startDate,
        endDate: this.endDate,
        isCarpenter: false,

      }).then(() => {
        // this.changeLoadingtoTrue(true);
      });
    },
    ExchangeEmployee(obj) {
      console.log('Before dispatching action. Payload:', obj);
      this.move_planned_employee_to_another_store({
        obj,
        successCallback: () => {
          this.employeePlanning({
            userId: this.manager,
            startDate: this.startDate,
            endDate: this.endDate,
            isCarpenter: false,

          })
        },
      })
    },
    AddPersonnelEmployees(value) {
      // console.log('method', value)
      if (this.tabIndex === 0) {
        const full = { ...value, isCarpenter: false }
        this.addEmployeePlanning({
          object: full,
          successCallback: () => {
            this.employeePlanning({
              userId: this.manager,
              startDate: this.startDate,
              endDate: this.endDate,
              isCarpenter: false,

            })
          },
        })
      } else {
        const full = { ...value, isCarpenter: true }
        this.addEmployeePlanning({
          object: full,
          successCallback: () => {
            this.employeePlanning({
              userId: this.manager,
              startDate: this.startDate,
              endDate: this.endDate,
              isCarpenter: true,

            })
          },
        })
      }
    },
    getStore(value) {
      this.storeIdd = value
    },
    checkEmployee(value) {
      // console.log('tt', value)
      // this.getTourNameByEmployeeUserIdStartAndEndShift({ employeeUserId: value, startShift: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), endShift: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') });
      this.potential_tours_by_employeePlanId({
        employeePlanningId: value,
      })
    },
    editPersonnelPlanning(value) {
      // if (this.tabindex == 0) {
      // const full = { ...value, isCarpenter: false }
      this.editEmployeePlanning({
        obj: value,
        successCallback: () => {
          this.employeePlanning({
            userId: this.manager,
            startDate: this.startDate,
            endDate: this.endDate,
            isCarpenter: false,

          })
        },
      })
      // } else {
      // const full = { ...value, isCarpenter: true }

      // this.editEmployeePlanning({
      //   obj: full,
      //   successCallback: () => {
      //     this.employeePlanning({
      //       userId: this.manager,
      //       startDate: this.startDate,
      //       endDate: this.endDate,
      //       isCarpenter: true,

      //     })
      //   },
      // })
      // }
    },
    deleteEmployee(value) {
      if (this.tabIndex == 0) {
        this.deleteEmployeePlanning({
          employeePlanId: value,
          successCallback: () => {
            this.employeePlanning({
              userId: this.manager,
              startDate: this.startDate,
              endDate: this.endDate,
              isCarpenter: false,

            })
          },
        })
      } else if (this.tabIndex == 1) {
        this.deleteEmployeePlanning({
          employeePlanId: value,
          successCallback: () => {
            this.employeePlanning({
              userId: this.manager,
              startDate: this.startDate,
              endDate: this.endDate,
              isCarpenter: true,

            })
          },
        })
      }
    },
    deleteEmployeer(value, value2) {
      this.userId = value;
      this.userIdd = value2
      // this.deleteEmployeePlanning({
      //   employeePlanId: value,
      //   successCallback: () => {
      //     this.employeePlanning({
      //       userId: this.manager,
      //       startDate: this.startDate,
      //       endDate: this.endDate,
      //     })
      //   },
      // })
    },
    // storeID(value) {
    //   // console.log(value)
    //         // this.changeLoadingtoTrue(false);


    //   this.loadFreeRegularEmployee({
    //     storeId: value,
    //     startShift: this.selectedDate,
    //     endShift: this.selectedDate,
    //     includeTeamLeaders: true,
    //     includeManager: false,
    //   }).then((res) => {
    //     console.log(res);
    //     this.changeLoadingtoTrue(true);
    //   });

    //   this.text = []
    //   for (let i = 0; i < this.getFreeRegularEmployee.length; i++) {
    //     this.text.push(`${this.getFreeRegularEmployee[i].userFullName} (${this.getFreeRegularEmployee[i].employeeType})  `)
    //   }
    // },
    // time1(value) {
    //   console.log('time1', value)
    //   this.time2 = value
    // },
    filteredStores(storeName) {
      return this.getStoreManagementOverview.filter((store) => { return store.storeName === storeName });
    },
    previousDay() {
      this.time1 = moment(this.time1, 'DD-MM-YYYY').subtract(1, 'days').format('DD-MM-YYYY');
    },
    nextDay() {
      this.time1 = moment(this.time1, 'DD-MM-YYYY').add(0.5, 'days').format('DD-MM-YYYY');
    },
    truckId(item) {
      this.truckShow = item;
    },
    dressId(id) {
      this.filialeIdShow = id;
      this.routeIdShow = ''
      this.truckShow = {}
    },
    routeId(id) {
      this.routeIdShow = id;
      this.truckShow = {}
    },
  },
};
</script>

<style lang="scss" scoped>
.truckBox{
 border: 1px solid rgb(246, 147, 147);
 display: flex;
 flex-direction: column;
//  justify-content: space-between;
 border-radius: 12px !important;
 text-align: left;
//  background-color:  rgb(183, 255, 177);
 min-height: 155px;
}

.personnelTable{
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 40px 40px 50px;
}

.personnelTable td{
  width: 12.5%;
  color: black;
  font-size: 14px;
  font-weight: 300;
  border-right: 20px solid rgba(0, 0, 0, 0.05);
}

.extEmployee{
  color:#262E6C;
  padding-left: 7px;
  padding-top: 8px;
}

.extNumber{
  color: #141414;
  font-weight: normal;
  padding-right: 30px;
  padding-top: 8px;
}

.empName{
  text-align: left;
  color:#262E6C;
  width: 42%;
}

.vacLeave{
  text-align: right;
  color:#262E6C;
  width: 50%;
}

#input-group-2{
  width: 18%;
}

#input-group-9{
  margin-bottom: 10px;
  width: 20%;
  margin-left: 10px;
  text-align: left;
}

.table-header{
  color: #242f6e;
  // background-color: aliceblue !important;
  // border-right: 1px solid lightgray;
  // border-bottom: 1px solid lightgray;
}

td{
  padding: 0 15px;
}
.truckBox.withRedBorder {
  border: 2px solid rgb(8, 180, 22);
}

span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}

.ext-emp td{
  color: black;
  font-size: 13px;
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.truckBox.withGreenBorder {
  border: 2px solid rgb(246, 147, 147);
}
.truckBox2{
 border: 1px solid rgba(0, 0, 0, 0.263);
 border-radius: 12px !important;
 text-align: left;
 background-color:  rgb(183, 255, 177);
 min-height: 95px;
}
.truckBox1{
 border-radius: 10px !important;
 text-align: left;
 min-height: 95px;
}
.externalEmployee {
  background-color: #f8e788;
 border-radius: 0px 0px 12px 12px !important;

}
.employeeBox{
  padding-top: 5px;
  padding-bottom: 10px;
  // background-color:rgb(183, 255, 177);
//  border-radius: 0px 0px 12px 12px !important;
}
.employeeBox span {
  display: inline-block;
  position: relative;
}

.employeeBox span::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 5px;
  width: calc(100% - 10px); /* Adjust the width to include the shorter sides */
  height: 2px;
   /* Set the underline color */
  border: 0.5px solid #A5A5A5;
opacity: 1;
  }
.table-header {
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
}
.product__management .hide-on-products {
  display: none;
}

.ext-emp{
  display: flex;
  text-align: left;
  flex-direction: column;
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  height: 100% !important;
  width: 20%;
}

@media screen and (max-width: 1500px){
  .vacLeave, .empName, .extEmployee{
    font-size: 13px;
  }

  .ext-emp td{
    font-size: 11px;
  }
}

@media screen and (max-width: 1437px){
  #input-group-2{
    width: 23%;
  }

  #input-group-9{
    width: 21%;
  }

  .ext-emp{
    width: 21%
  }
}

@media screen and (max-width: 1190px){
  .main{
  padding: 90px 15px 15px 30px !important;
  }
}

@media screen and (max-width: 1151px) {
  .main {
    padding: 90px 0.75rem 0 0.75rem;
  }

  .vacLeave, .empName, .extEmployee{
    font-size: 12px;
  }

  .extEmployee{
    padding-left: 4px;
  }

  .ext-emp td{
    font-size: 9px;
  }

  .extNumber{
    padding-right: 15px;
    font-size: 12px;
  }

  #input-group-2{
    width: 28%;
  }

  #input-group-9{
    width: 25%;
  }

  .personnelTable{
    font-size: 13px;
  }

  .personnelTable td{
    font-size: 12px;
  }
}

@media screen and (max-width: 896px) {
  .main {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
}
</style>
