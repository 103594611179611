<template>
  <div>
    <b-modal
      id="delete-modal-request"
      ref="modal"
      header-class="headerModal"
      centered
      title="Delete User Modal"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form v-if="getPotentialToursByEmployeePlanId.length > 0">
        <div
          v-for="item in getPotentialToursByEmployeePlanId"
          :key="item.tourTruckId"
        >
          <div
            style="
          background-color: gainsboro;
          border-radius: 8px;
          max-width: 100%;
          padding: 10px;
          min-height: 56px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
          >
            <div
              style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 10px;"
            >
              <b-form-group
                id="input-group-8"
                label="Route Name"
                label-for="input-25"
                style="margin-right: 5px; text-align: left;"
              >
                {{ item.tourName }}
              </b-form-group>
              <b-form-group
                id="input-group-8"
                label="No. of employees"
                label-for="input-25"
                style="text-align: left;"
              >
                {{ item.employeeCounter }}
              </b-form-group>
              <div style="display: flex; flex-direction: column;">
                <b-button
                  type="button"
                  variant="none"
                  style="margin-bottom: 5px"
                  class="buttonSubmit"
                  @click="replaceShow(item)"
                >
                  Replace
                </b-button>
                <b-button
                  v-if="item.employeeCounter !== 1"
                  type="button"
                  variant="none"
                  class="buttonSubmit"
                  @click="handleReleaseClick(item.truckStaffId), selectedItemId = item.tourTruckId"
                >
                  Remove
                </b-button>
              </div>
            </div>
            <b-form-group
              v-if="replace == true && item.tourTruckId === selectedItemId"

              id="input-group-8"
              style="width: 100%; padding-top: 20px;"
              label="Select Free Employee"
              label-for="input-25"
            >
              <vue-select
                id="input-8"
                v-model="substituteEmployee"
                :options="freeEmployeesWithoutEmployee"
                label="userFullName"
              />
            </b-form-group>
            <b-button
              v-if="replace == true && item.tourTruckId === selectedItemId"

              type="button"
              variant="none"
              style="margin-top: 10px; width: 100px;"
              class="buttonSubmit"
              @click="replaceEmployee(item.truckStaffId)"
            >
              Save
            </b-button>
          </div>
        </div>
      </b-form>
      <div
        v-else
        style="margin-top: 5px;"
      >
        <p>{{ $t('AreYouSureForRemoving') }}</p>


        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          :disabled="getPotentialToursByEmployeePlanId.length > 0 "
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment'

export default {
  components: {},
  // eslint-disable-next-line vue/require-prop-types
  props: ['employee', 'storeId', 'id', 'date', 'manageri', 'tabIndex', 'time1'],
  data() {
    return {
      replace: false,
      selectedItemId: null,
      substituteEmployee: null,

    };
  },
  computed: {
    ...mapGetters(['getPotentialToursByEmployeePlanId', 'getfreeTeamLeaders', 'getFreeEmployeesNew', 'getVacationRequests', 'getPotentialToursByEmployeePlanId', 'getfreeEmployees']),

    freeEmployeesWithoutEmployee() {
    // Assuming getfreeEmployees returns an array of employee objects
      return this.getFreeEmployeesNew.filter((emp) => { return emp.userId !== this.id });
    },
  },
  methods: {
    ...mapActions(['freeEmployees_new', 'employeePlanning', 'releaseEmployeeFromTour', 'getTourNameByEmployeeUserIdStartAndEndShift', 'loadUsersFullNamesById', 'cancelVacation', 'freeEmployee', 'releaseAndReplaceEmployeeFromTour']),
    replaceShow(value) {
      this.replace = !this.replace;
      this.selectedItemId = value.tourTruckId
      // const startDate = this.getPotentialToursByEmployeePlanId.length.find((item) => { return item.tourTruckId === this.selectedItemId }).startDate;
      // const endDate = this.getPotentialToursByEmployeePlanId.length.find((item) => { return item.tourTruckId === this.selectedItemId }).endDate;
      this.freeEmployees_new({
        userId: this.manageri, startShift: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), endShift: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      });
    },
    async replaceEmployee(value) {
      await this.releaseAndReplaceEmployeeFromTour(
        {
          obj: {
            truckStaffId: value,
            // tourTruckId: this.selectedItemId,
            employeePlanId: this.employee,
            storeId: null,
            substituteEmployee: this.substituteEmployee.userId,
            message: '',
            dateReleased: moment.utc().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          },
          successCallback: () => {
            // this.getTourNameByEmployeeUserIdStartAndEndShift({
            //   employeeUserId: this.form.employeeUserId, startShift: new Date().toJSON().slice(0, 10).replace(/-/g, '-'), endShift: null,
            // });
            // this.substituteEmployee = null;
            // this.substituteTeamLeader = null;
            this.getTourNameByEmployeeUserIdStartAndEndShift({ employeeUserId: this.employee, startShift: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), endShift: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') });
            this.employeePlanning({
              userId: this.manageri,
              startDate: moment(this.date).hour(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              endDate: moment(this.date).hour(23).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              isCarpenter: true,
            })
          },
        },
      );
      this.$refs.modal.hide()
      this.onReset();
    },
    handleReleaseClick(valuee) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to release this employee?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Release',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            this.releaseEmployee(valuee);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },
    async releaseEmployee(test) {
      await this.releaseEmployeeFromTour({
        obj: {
          truckStaffId: test,
          message: '',
          dateReleased: moment().tz(moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        },
        successCallback: () => {
          if (this.tabIndex == 0) {
            this.employeePlanning({
              userId: this.manageri,
              startDate: moment(this.time1, 'DD-MM-YYYY').hour(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              endDate: moment(this.time1, 'DD-MM-YYYY').hour(23).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              isCarpenter: false,

            })
          } else if (this.tabIndex == 1) {
            this.employeePlanning({
              userId: this.manageri,
              startDate: moment(this.time1, 'DD-MM-YYYY').hour(0).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              endDate: moment(this.time1, 'DD-MM-YYYY').hour(23).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
              isCarpenter: true,

            })
          }
        },
      });
      this.$refs.modal.hide()
      this.onReset();
    },
    onSubmit() {
      this.$refs.modal.hide()
      this.$emit('cancel', this.employee);
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.selectedItemId = null;
      this.reason = '';
      this.time1 = '';
      this.time2 = '';
      this.formSubmitted = false;
      this.form2.coManagerId = null;
      this.manager = null;
      this.substituteEmployee = null
      this.replace = false
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
