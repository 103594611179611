<template>
  <div>
    <b-modal
      id="add-personnel-to-planning"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddEmployee')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <!-- @shown="freeEmployeess" -->
      <b-form>
        <b-form-group
          id="input-group-8"
          :label="$t('Filiale')"
          style="width:100%;  margin-right: 10px;"
          label-for="input-25"
        >
          <vue-select
            id="input-5"
            v-model="store"
            required
            :options="getFiliales"
            label="storeName"
            :clearable="false"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
          <!-- :reduce="(e) => e.storeId" -->
          <!-- :state="validateState('storeId')" -->
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t("This_is_a_required_field_and_must_be_at_least_3_letters")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <div style="display: flex; justify-content: center;">
          <div
            style="    display: flex;
    justify-content: space-around;"
          >
            <b-form-checkbox
              id="checkbox-2"
              v-model="isCheckedToday"
              name="checkbox-2"
              :value="true"
              :unchecked-value="false"
              @input="setToday(isCheckedToday)"
            >
              {{ $t('Today') }}
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox-1"
              v-model="isChecked"
              name="checkbox-1"
              :value="true"
              :disabled="!time1"
              :unchecked-value="false"
              @input="setDefaultTime(isChecked)"
            >
              {{ $t('AllDay') }}
            </b-form-checkbox>
          </div>
          <div
            style="display: flex; justify-content: space-around;"
          >
            <b-form-checkbox
              id="checkbox-2"
              v-model="isCheckedMorning"
              name="checkbox-2"
              :value="true"
              :unchecked-value="false"
              @input="setMorning(isCheckedMorning)"
            >
              {{ $t('Morning') }}
            </b-form-checkbox>
            <b-form-checkbox
              id="checkbox-1"
              v-model="isCheckedAfternoon"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              @input="setAfternoon(isCheckedAfternoon)"
            >
              {{ $t('Afternoon') }}
            </b-form-checkbox>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between;">
          <b-form-group
            id="input-group-9"
            :label="$t('StartingDate')"
            label-for="input-9"
          >
            <date-picker
              v-model="$v.time1.$model"
              type="datetime"
              value-type="format"
              :placeholder="$t('Select')"
              :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
              aria-describedby="input-1-live-feedback"
              :lang="lang"
            />
            <!-- :state="validateState('time1')" -->
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t("This_is_a_required_field_and_must_be_at_least_3_letters")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-9"
            :label="$t('EndingDate')"
            label-for="input-9"
          >
            <date-picker
              v-model="$v.time2.$model"
              type="datetime"
              value-type="format"
              :placeholder="$t('Select')"
              :format="'DD-MM-YYYY' + ' ' + 'HH:mm'"
              aria-describedby="input-1-live-feedback"
              :lang="lang"
            />
            <!-- :state="validateState('time1')" -->
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t("This_is_a_required_field_and_must_be_at_least_3_letters")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group
          id="input-group-8"
          :label="$t('Employeers')"
          style="width:100%;  margin-right: 10px;"
          label-for="input-25"
        >
          <vue-select
            id="input-5"
            v-model="form.employeeUserIds"
            required
            aria-multiline="2"
            multiple
            :options="destinationOptions"
            label="userFullName"
            :reduce="(e) => e.userId"
            :clearable="false"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <pre />
        <div>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment'
import {
  required,
} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';


export default {
  components: {
    DatePicker,

  },
  mixins: [validationMixin],

  props: ['manager'],
  data() {
    return {
      userId: null,
      tourTruckId: null,
      store: null,
      time1: null,
      time2: null,
      isChecked: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      isCheckedToday: false,
      isCheckedMorning: false,
      isCheckedAfternoon: false,
      employeerss: [],
      employeers: [{
        name: null,
      }],
      form: {
        storeId: null,
        storeGln: null,
        employeeUserIds: [
        ],
        startDate: null,
        endDate: null,
      },
      // form: {
      //   truckStaffReponsibilities: [
      //     {
      //       employeeUserId: '',
      //       isDriver: false,
      //       isResponsible: false,
      //     },
      //   ],
      //   startTour: this.routeId.startShift,
      //   endTour: this.routeId.endShift,
      // },
    };
  },
  validations: {
    form: {
      storeId: {
        required,
      },
      employeeUserIds: {
        required,
      },
    },
    time1: {
      required,
    },
    time2: {
      required,
    },
  },
  computed: {
    ...mapGetters([
      'getfreeEmployees', 'getFiliales', 'getFreeEmployeesNew',
    ]),
    isValidDate() {
      if (!this.time1 || !this.time2) {
        return true; // allow empty dates
      }
      const start = moment(this.time1, 'DD-MM-YYYY HH:mm').toDate();
      const end = moment(this.time2, 'DD-MM-YYYY HH:mm').toDate();
      return start <= end;
    },
    destinationOptions() {
      return this.getFreeEmployeesNew.filter((filiale) => {
        return filiale.userId !== this.form.employeeUserIds
      })
    },
  },
  watch: {

    store(value) {
      this.form.storeId = value.storeId;
      this.form.storeGln = value.storeGLN;
    },

    time1(value) {
      if (this.setDefaultTime == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:00')
        this.form.startDate = temp1
      } else if (this.setToday == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:00')
        this.form.startDate = temp1
      } else if (this.setAfternoon == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT12:31:00')
        this.form.startDate = temp1
      } else if (this.setMorning == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:00')
        this.form.startDate = temp1
      }
    },
    time2(value) {
      if (this.setDefaultTime == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:00')
        this.form.endDate = temp1
      } else if (this.setToday == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:00')
        this.form.endDate = temp1
      } else if (this.setMorning == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT12:30:00')
        this.form.endDate = temp1
      } else if (this.setAfternoon == value) {
        const temp1 = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:00')
        this.form.endDate = temp1
      }
      this.freeEmployees_new({
        userId: this.manager,
        startShift: this.form.startDate,
        endShift: this.form.endDate,
      })
    },
    isValidDate(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.time2 = null;
        this.isChecked = false;
        this.isCheckedMorning = false;
        this.isCheckedAfternoon = false;
        this.isCheckedToday = false;
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['freeEmployee', 'freeEmployees_new', 'resetFreeEmployee_new']),
    setDefaultTime(value) {
      if (value == true) {
        this.time1 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 08:00`;
        this.time2 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 17:00`;
        this.form.startDate = `${moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:00')}`;
        this.form.endDate = `${moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:00')}`;
        this.isCheckedMorning = false;
        this.isCheckedAfternoon = false;
        this.isCheckedToday = false;
      } else {
        // this.form.startDate = '';
        // this.form.endDate = '';
        // this.time1 = '';
        // this.time2 = '';
      }
    }, // Set the default value using moment.js library
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setToday(value) {
      if (value == true) {
        this.form.startDate = `${moment().format('YYYY-MM-DDT08:00:ss')}`;
        this.time1 = `${moment().format('DD-MM-YYYY')} 08:00`;
        this.time2 = `${moment().format('DD-MM-YYYY')} 17:00`;
        this.form.endDate = `${moment().format('YYYY-MM-DDT17:00:ss')}`;
        this.isCheckedMorning = false;
        this.isCheckedAfternoon = false;
        this.isChecked = false;
      } else {
        // this.form.startDate = '';
        // this.form.endDate = '';
        // this.time1 = '';
        // this.time2 = '';
      }
    },
    setMorning(value) {
      if (value == true) {
        if (this.time1 !== null) {
          this.time1 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 08:00`;
          this.time2 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 12:30`;
          this.form.startDate = `${moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:ss')}`;
          this.form.endDate = `${moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT12:30:ss')}`;
        } else if (this.time1 == null) {
          this.time1 = `${moment().format('DD-MM-YYYY')} 08:00`;
          this.form.startDate = `${moment().format('YYYY-MM-DDT08:00:00')}`;
          this.time2 = `${moment().format('DD-MM-YYYY')} 12:30`;
          this.form.endDate = `${moment().format('YYYY-MM-DDT12:30:00')}`;
        }
        this.isCheckedAfternoon = false;
        this.isChecked = false;
        this.isCheckedToday = false;
      } else {
        // this.form.startDate = '';
        // this.form.endDate = '';
        // this.time1 = null;
        // this.time2 = null;
      }
    },
    setAfternoon(value) {
      if (value == true) {
        if (this.time1 !== null) {
          this.time1 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 12:31`;
          this.time2 = `${moment(this.time1, 'DD-MM-YYYY').format('DD-MM-YYYY')} 17:00`;
          this.form.startDate = `${moment(this.time1, 'DD-MM-YYYY').format('YYYY-MM-DDT12:31:00')}`;
          this.form.endDate = `${moment(this.time2, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:00')}`;
        } else if (this.time1 == null) {
          this.form.startDate = `${moment().format('YYYY-MM-DDT12:31:00')}`;
          this.time1 = `${moment().format('DD-MM-YYYY')} 12:31`;
          this.time2 = `${moment().format('DD-MM-YYYY')} 17:00`;
          this.form.endDate = `${moment().format('YYYY-MM-DDT17:00:00')}`;
        }
        this.isCheckedMorning = false;
        this.isChecked = false;
        this.isCheckedToday = false;
      } else {
        // this.form.startDate = '';
        // this.form.endDate = '';
        // this.time1 = null;
        // this.time2 = null;
      }
    },
    // removeEmployee(index) {
    //   this.form.truckStaffReponsibilities.splice(index, 1);
    // },
    // removeSelectedEmployee(index) {
    //   const selectedEmployees = this.form.truckStaffReponsibilities.map((emp) => { return emp.employeeUserId });
    //   const filteredEmployees = this.getfreeEmployees.filter((emp) => { return !selectedEmployees.includes(emp.userId) });
    //   this.filteredEmployees[index] = filteredEmployees;
    // },
    // uncheckedValueIsResponsible(clickedEmployee) {
    //   this.form.truckStaffReponsibilities.forEach((employee) => {
    //     if (employee !== clickedEmployee) {
    //       employee.isResponsible = false;
    //     }
    //   });
    // },
    // uncheckedValueIsDriver(clickedEmployee) {
    //   this.form.truckStaffReponsibilities.forEach((employee) => {
    //     if (employee !== clickedEmployee) {
    //       employee.isDriver = false;
    //     }
    //   });
    // },
    // checkEmployeeDeselect(deselectedValue, employee) {
    //   if (deselectedValue && deselectedValue.userId === employee.employeeUserId.userId) {
    //     employee.employeeUserId = null;
    //   }
    // },
    // onRemove(removedOption) {
    //   this.freeEmployee({
    //     storeId: this.filialeId.storeId, startShift: this.routeId.startShift, endShift: this.routeId.endShift, includeTeamLeaders: true, includeManager: false,
    //   });
    //   console.log(removedOption)
    // // Call any other methods or update any data as necessary
    // },
    // freeEmployeess() {
    //   this.freeEmployee({
    //     storeId: this.filialeId.storeId, startShift: this.routeId.startShift, endShift: this.routeId.endShift, includeTeamLeaders: true, includeManager: false,
    //   });
    //   this.tourTruckId = this.truckId.tourTruckId;
    //   this.form.startTour = this.routeId.startShift;
    //   this.form.endTour = this.routeId.endShift;
    // },
    addNewRow() {
      this.employeers.push({
        name: null,
      })
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('AddPersonnel', this.form)
      this.$refs.modal.hide();
      this.onReset();
      // setTimeout(() => {
      //   this.$v.$reset();
      // }, 0);
      // this.$nextTick(() => {
      //   this.$v.$reset();
      // });
      // Promise.resolve()
      //   .then(() => {
      //     this.$emit('click', { param1: this.form, param2: this.tourTruckId });
      //     // this.$emit('test', this.tourTruckId);
      //   })
      //   .then(() => {
      //     this.$refs.modal.hide();
      //   })
      //   .then(() => {
      //     this.onReset();
      //   })

      // setTimeout(() => {
      //   this.$v.$reset();
      // }, 0);
      // this.$nextTick(() => {
      //   this.$v.$reset();
      // });
      this.resetFreeEmployee_new();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
      this.resetFreeEmployee_new()
    },
    onReset() {
      this.form = {}
      this.store = null
      this.isChecked = false
      this.isCheckedToday = false;
      this.isCheckedMorning = false;
      this.isCheckedAfternoon = false;
      this.time1 = null;
      this.time2 = null;
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
