<template>
  <div>
    <b-modal
      id="exchange-planning"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ExchangeEmployee')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <!-- @shown="freeEmployeess" -->
      <b-form>
        <div style="display: flex;">
          <b-form-group
            id="input-group-8"
            :label="$t('Filiale')"
            style="width:100%;  margin-right: 10px;"
            label-for="input-25"
          >
            <vue-select
              id="input-5"
              v-model="store"
              required
              :options="storeNames"
              label="storeName"
              :clearable="false"
              :placeholder="$t('Select')"
              aria-describedby="input-1-live-feedback"
            />
            <!-- :reduce="(e) => e.storeId" -->
            <!-- :state="validateState('storeId')" -->
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t("This_is_a_required_field_and_must_be_at_least_3_letters")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-8"
            :label="$t('Employeers')"
            style="width:100%;  margin-right: 10px;"
            label-for="input-25"
          >
            <vue-select
              id="input-5"
              v-model="form.employeeUserId"
              required
              aria-multiline="2"
              :options="destinationOptions"
              label="userFullName"
              :reduce="(e) => e.userId"
              :clearable="false"
              :placeholder="$t('Select')"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </div>
        <div
          v-if="form.employeeUserId != null"
          style="display: flex;padding-top: 5px;"
        >
          <b-form-group
            id="input-group-8"
            :label="$t('Filiale to exchange to')"
            style="width:100%;  margin-right: 10px;"
            label-for="input-25"
          >
            <vue-select
              id="input-5"
              v-model="store2"
              required
              :options="storeNames"
              label="storeName"
              :clearable="false"
              :placeholder="$t('Select')"
              aria-describedby="input-1-live-feedback"
            />
            <!-- :reduce="(e) => e.storeId" -->
            <!-- :state="validateState('storeId')" -->
            <b-form-invalid-feedback id="input-1-live-feedback">{{
              $t("This_is_a_required_field_and_must_be_at_least_3_letters")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            id="input-group-8"
            :label="$t('Date')"
            style="width:100%;  margin-right: 10px;"
            label-for="input-25"
          >
            <b-input
              v-model="time"
              disabled
            />
          </b-form-group>
        </div>
        <pre />
        <div>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment'
import {
  required,
} from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';


export default {
  components: {
    // DatePicker,

  },
  mixins: [validationMixin],

  props: ['manager', 'time', 'storeNames'],
  data() {
    return {
      userId: null,
      tourTruckId: null,
      store: null,
      store2: null,
      time1: null,
      time2: null,
      firstFiliale: null,
      firstFilialeGLN: null,
      isChecked: false,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      isCheckedToday: false,
      isCheckedMorning: false,
      isCheckedAfternoon: false,
      employeerss: [],
      employeers: [{
        name: null,
      }],
      form: {
        storeId: null,
        isCarpenter: false,
        // storeGln: null,
        employeeUserId: null,
        startDate: null,
        endDate: null,
      },
      // form: {
      //   truckStaffReponsibilities: [
      //     {
      //       employeeUserId: '',
      //       isDriver: false,
      //       isResponsible: false,
      //     },
      //   ],
      //   startTour: this.routeId.startShift,
      //   endTour: this.routeId.endShift,
      // },
    };
  },
  validations: {
    form: {
      storeId: {
        required,
      },
      employeeUserId: {
        required,
      },
    },
    time1: {
      required,
    },
    time2: {
      required,
    },
  },
  computed: {
    ...mapGetters([
      'getfreeEmployees', 'getFiliales', 'getFreeEmployeesNew', 'getFreePlannedEmployees',
    ]),
    destinationOptions() {
      return this.getFreePlannedEmployees.filter((filiale) => {
        return filiale.userId !== this.form.userFullName
      })
    },
  },
  watch: {

    store(value) {
      this.firstFiliale = value.storeId;
      this.firstFilialeGLN = value.storeGLN;
      // this.freeEmployees_new({
      //   userId: this.manager,
      //   startShift: moment(this.time).format('YYYY-MM-DD'),
      //   endShift: moment(this.time).format('YYYY-MM-DD'),
      // })
      this.loadPlannedEmployees({
        storeId: value.storeId,
        startDate: moment(this.time, 'DD-MM-YYYY').format('YYYY-MM-DDT08:00:00.SSSZ'),
        endDate: moment(this.time, 'DD-MM-YYYY').format('YYYY-MM-DDT17:00:00.SSSZ'),
        isCarpenter: false,
      })
    },
    // 'form.employeeUserId': {
    //   handler(value) {
    //     if (value != null) {
    //       this.loadFilialePagination({
    //         status: true,
    //         storeName: null,
    //         pageNumber: 1,
    //         pageSize: 50,
    //       })
    //     }
    //     console.log('valuee', value)
    //   },
    //   deep: true,
    // },
    store2(value) {
      this.form.storeId = value.storeId;
      // this.form.storeGln = value.storeGLN;
      this.form.startDate = moment(this.time, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.form.endDate = moment(this.time, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['freeEmployee', 'freeEmployees_new', 'resetFreeEmployee_new', 'loadFilialePagination', 'loadPlannedEmployees']),
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('exchange', this.form)
      this.$refs.modal.hide();
      this.onReset();
      this.resetFreeEmployee_new();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
      this.resetFreeEmployee_new()
    },
    onReset() {
      this.form.storeId = null
      this.form.employeeUserId = null
      this.form.endDate = null
      this.form.startDate = null
      this.store = null
      this.store2 = null
      this.isChecked = false
      this.isCheckedToday = false;
      this.isCheckedMorning = false;
      this.isCheckedAfternoon = false;
      this.time1 = null;
      this.time2 = null;
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
}
.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
