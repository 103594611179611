var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{ref:"modal",attrs:{"id":"leave-request-personnel-planning","header-class":"headerModal","centered":"","title":_vm.$t('VacationLeaveReq'),"size":"l","hide-footer":""}},[_c('b-form',{staticClass:"vacation"},[_c('b-form-group',{attrs:{"id":"input-group-5","label":_vm.$t('VacationVacationT'),"label-for":"input-2"}},[_c('vue-select',{attrs:{"id":"input-5","required":"","options":[
            { value: 'Vacation', label: _vm.$t('Vacation') },
            { value: 'SickLeave', label: _vm.$t('AddNew19') },
            { value: 'AccidentLeave', label: _vm.$t('AddNew20') },
            { value: 'Education', label: _vm.$t('AddNew21') },
            { value: 'SpecialLeave', label: _vm.$t('AddNew22') },
            { value: 'Maternity', label: _vm.$t('AddNew23') },
            { value: 'Appointment', label: _vm.$t('AddNew24') },
            { value: 'DeathCase', label: _vm.$t('AddNew46') },
          ],"placeholder":_vm.$t('Select'),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.formSubmitted && !_vm.$v.reason.required),expression:"formSubmitted && !$v.reason.required"}],staticStyle:{"color":"red"}},[_vm._v("Please select a reason.")])],1),(_vm.showInput)?_c('b-form-group',{attrs:{"id":"input-group-8","label":"Other reason","label-for":"input-25"}},[_c('b-form-input',{staticStyle:{"height":"80px"},attrs:{"id":"input-8"},model:{value:(_vm.form.vacationType),callback:function ($$v) {_vm.$set(_vm.form, "vacationType", $$v)},expression:"form.vacationType"}})],1):_vm._e()],1),_c('b-form',{staticClass:"form2"},[_c('div',[_c('b-button',{staticClass:"buttonSubmit",attrs:{"type":"button","variant":"none"},on:{"click":_vm.onSubmitRequestVacation}},[_vm._v(" "+_vm._s(_vm.$t('VacationSubmit'))+" ")]),_c('b-button',{staticClass:"buttonCancel",staticStyle:{"margin-right":"15px"},attrs:{"type":"button","variant":"danger"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(_vm.$t('VacationCancel'))+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }